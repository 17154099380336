import tw, { styled } from "twin.macro"

import { GlobalStyles } from "../Styled/Global"

export const IconCaption = tw.figcaption`w-full mt-4 font-sans font-bold text-xs text-center py-1 bg-white`
export const IconSwatch = tw.figure`flex flex-col items-center justify-between pt-4 w-full bg-grey-lighter flex items-center justify-center overflow-hidden shadow mb-7.5 rounded-lg`

export const ShadowCaption = tw.figcaption`absolute font-sans font-bold text-xl text-center`
export const ShadowSwatch = styled.figure`
  ${tw`flex flex-col items-center justify-center h-32 h-32 overflow-hidden mb-7.5 rounded-lg`}
  ${({ depth }: any) => (depth && GlobalStyles.shadow[depth]) || (depth && GlobalStyles.shadow[`default`])}
`

export const ColourCaption = tw.figcaption`absolute bottom-0 inset-x-0 font-sans font-bold text-xs text-center py-1 bg-white`
export const ColourSwatch = styled.figure`
  ${tw`relative w-full h-32 overflow-hidden shadow mb-7.5 rounded-lg`}
  ${({ colour }: any) => colour && GlobalStyles.background[colour]}
`

export const Section = styled.section`
  ${tw`py-2`}
  ${({ colour }: any) => colour && GlobalStyles.background[colour]}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}
`
