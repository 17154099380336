import React from "react"

import { Accordion } from "../Accordion/Accordion"
import { Icon } from "../Icon/Icon"
import { FormInput } from "../Form/Input/FormInput"
import { FormSelect } from "../Form/Select/FormSelect"
import { FormTextarea } from "../Form/Textarea/FormTextarea"
import { StyledButton, StyledLink } from "../Styled/Button"
import { StyledBreadcrumbs, StyledBreadcrumb } from "../Styled/Breadcrumb"
import { StyledForm } from "../Styled/Form"
import { StyledMeatballs, StyledMeatball } from "../Styled/Meatball"
import { StyledPills, StyledPill } from "../Styled/Pill"
import { StyledContainer } from "../Styled/Container"
import { StyledColumn } from "../Styled/Column"
import { StyledRow } from "../Styled/Row"
import { StyledRule } from "../Styled/Rule"
import { H1, H2, H3, H4, H5, H6, P, Small, Overline, RichText } from "../Styled/Text"
import { IconSwatch, IconCaption, Section, ColourSwatch, ColourCaption, ShadowSwatch, ShadowCaption } from "./styledStyleguide"

export const Styleguide = (): JSX.Element => (
  <StyledContainer width={`xl`}>
    <H4 withSpacing={`xs`}>Text</H4>
    <StyledRule withSpacing />
    <H1 withSpacing>H1 — The quick brown fox jumped over the lazy dog.</H1>
    <H2 withSpacing>H2 — The quick brown fox jumped over the lazy dog.</H2>
    <H3 withSpacing>H3 — The quick brown fox jumped over the lazy dog.</H3>
    <H4 withSpacing={`xs`}>H4 — The quick brown fox jumped over the lazy dog.</H4>
    <H5 withSpacing>H5 — The quick brown fox jumped over the lazy dog.</H5>
    <H6 uppercase withSpacing>
      H6 — The quick brown fox jumped over the lazy dog.
    </H6>
    <P withSpacing>Paragraph — The quick brown fox jumped over the lazy dog.</P>
    <Small large withSpacing>
      Paragraph (Small) — The quick brown fox jumped over the lazy dog.
    </Small>
    <Small withSpacing>Paragraph (Xsmall) — The quick brown fox jumped over the lazy dog.</Small>
    <Overline large withSpacing>
      Overline — The quick brown fox jumped over the lazy dog.
    </Overline>
    <Overline withSpacing>Overline (small) — The quick brown fox jumped over the lazy dog.</Overline>
    <RichText withSpacing>
      <ul>
        <li>
          The quick brown fox jumped over the lazy dog. The quick brown fox jumped over the lazy dog. The quick brown fox jumped over the lazy dog.
          The quick brown fox jumped over the lazy dog. The quick brown fox jumped over the lazy dog.
        </li>
        <li>The quick brown fox jumped over the lazy dog.</li>
        <li>The quick brown fox jumped over the lazy dog.</li>
        <li>The quick brown fox jumped over the lazy dog.</li>
      </ul>
      <ol>
        <li>
          The quick brown fox jumped over the lazy dog. The quick brown fox jumped over the lazy dog. The quick brown fox jumped over the lazy dog.
        </li>
        <li>The quick brown fox jumped over the lazy dog.</li>
        <li>The quick brown fox jumped over the lazy dog.</li>
        <li>The quick brown fox jumped over the lazy dog.</li>
      </ol>
    </RichText>

    <H4 withSpacing={`xs`}>Colour</H4>
    <StyledRule withSpacing />
    <StyledRow justify={`start`} gutter withSpacing withWrap>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`black`}>
          <ColourCaption>Black</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`white`}>
          <ColourCaption>White</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
    </StyledRow>
    <StyledRow justify={`start`} gutter withSpacing withWrap>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`grey-darkest`}>
          <ColourCaption>Grey Darkest</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`grey-darker`}>
          <ColourCaption>Grey Darker</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`grey-dark`}>
          <ColourCaption>Grey Dark</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`grey`}>
          <ColourCaption>Grey</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`grey-light`}>
          <ColourCaption>Grey Light</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`grey-lighter`}>
          <ColourCaption>Grey Lighter</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
    </StyledRow>
    <StyledRow justify={`start`} gutter withSpacing withWrap>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`green-dark`}>
          <ColourCaption>Green Dark</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`green-dark-pastel`}>
          <ColourCaption>Green Dark Secondary</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`green`}>
          <ColourCaption>Green</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`green-pastel`}>
          <ColourCaption>Green Secondary</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`green-light`}>
          <ColourCaption>Green Light</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`green-light-pastel`}>
          <ColourCaption>Green Light Secondary</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
    </StyledRow>
    <StyledRow justify={`start`} gutter withSpacing withWrap>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`orange-dark`}>
          <ColourCaption>Orange Dark</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`orange-dark-pastel`}>
          <ColourCaption>Orange Dark Secondary</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`orange`}>
          <ColourCaption>Orange</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`orange-pastel`}>
          <ColourCaption>Orange Secondary</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`orange-light`}>
          <ColourCaption>Orange Light</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`orange-light-pastel`}>
          <ColourCaption>Orange Light Secondary</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
    </StyledRow>
    <StyledRow justify={`start`} gutter withSpacing withWrap>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`pink-dark`}>
          <ColourCaption>Pink Dark</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`pink-dark-pastel`}>
          <ColourCaption>Pink Dark Secondary</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`pink-light`}>
          <ColourCaption>Pink Light</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`pink-light-pastel`}>
          <ColourCaption>Pink Light Secondary</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
    </StyledRow>
    <StyledRow justify={`start`} gutter withSpacing withWrap>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`purple-dark`}>
          <ColourCaption>Purple Dark</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`purple-dark-pastel`}>
          <ColourCaption>Purple Dark Secondary</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`purple`}>
          <ColourCaption>Purple</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`purple-pastel`}>
          <ColourCaption>Purple Secondary</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`purple-light`}>
          <ColourCaption>Purple Light</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`purple-light-pastel`}>
          <ColourCaption>Purple Light Secondary</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`red`}>
          <ColourCaption>Red</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`red-pastel`}>
          <ColourCaption>Red Secondary</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
    </StyledRow>
    <StyledRow justify={`start`} gutter withSpacing withWrap>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`yellow-dark`}>
          <ColourCaption>Yellow Dark</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`yellow-dark-pastel`}>
          <ColourCaption>Yellow Dark Secondary</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`yellow-light`}>
          <ColourCaption>Yellow Light</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ColourSwatch colour={`yellow-light-pastel`}>
          <ColourCaption>Yellow Light Secondary</ColourCaption>
        </ColourSwatch>
      </StyledColumn>
    </StyledRow>

    <H4 withSpacing={`xs`}>Depth</H4>
    <StyledRule withSpacing />
    <StyledRow justify={`start`} gutter withSpacing withWrap>
      <StyledColumn width={`md:1/6`} gutter>
        <ShadowSwatch depth={`xs`}>
          <ShadowCaption>XS</ShadowCaption>
        </ShadowSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ShadowSwatch depth={`sm`}>
          <ShadowCaption>SM</ShadowCaption>
        </ShadowSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ShadowSwatch depth>
          <ShadowCaption>MD</ShadowCaption>
        </ShadowSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ShadowSwatch depth={`lg`}>
          <ShadowCaption>LG</ShadowCaption>
        </ShadowSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/6`} gutter>
        <ShadowSwatch depth={`xl`}>
          <ShadowCaption>XL</ShadowCaption>
        </ShadowSwatch>
      </StyledColumn>
    </StyledRow>

    <H4 withSpacing={`xs`}>Icons</H4>
    <StyledRule withSpacing />
    <StyledRow justify={`start`} gutter withSpacing withWrap>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`account`} width={50} />
          <IconCaption>account</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`bag`} width={50} />
          <IconCaption>bag</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`check`} width={50} />
          <IconCaption>check</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`close`} width={50} />
          <IconCaption>close</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`cross`} width={50} />
          <IconCaption>cross</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`delete`} width={50} />
          <IconCaption>delete</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`down`} width={50} />
          <IconCaption>down</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`download`} width={50} />
          <IconCaption>download</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`drop`} width={50} />
          <IconCaption>drop</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`edit`} width={50} />
          <IconCaption>edit</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`email`} width={50} />
          <IconCaption>email</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`eye`} width={50} />
          <IconCaption>eye</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`eye-closed`} width={50} />
          <IconCaption>eye-closed</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`heart`} width={50} />
          <IconCaption>heart</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`heart-outline`} width={50} />
          <IconCaption>heart-outline</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`left`} width={50} />
          <IconCaption>left</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`location`} width={50} />
          <IconCaption>location</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`menu`} width={50} />
          <IconCaption>menu</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`minus`} width={50} />
          <IconCaption>minus</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`play`} width={50} />
          <IconCaption>play</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`pause`} width={50} />
          <IconCaption>pause</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`plus`} width={50} />
          <IconCaption>plus</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`right`} width={50} />
          <IconCaption>right</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`search`} width={50} />
          <IconCaption>search</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`star`} width={50} />
          <IconCaption>star</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`tick`} width={50} />
          <IconCaption>tick</IconCaption>
        </IconSwatch>
      </StyledColumn>
    </StyledRow>

    <StyledRow gutter justify={`start`} withSpacing withWrap>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-allantoin`} width={50} />
          <IconCaption>brand-allantoin</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-aloe-vera`} width={50} />
          <IconCaption>brand-aloe-vera</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-apricot-kernal`} width={50} />
          <IconCaption>brand-apricot-kernal</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-australia`} width={50} />
          <IconCaption>brand-australia</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-avocado`} width={50} />
          <IconCaption>brand-avocado</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-bisabolol`} width={50} />
          <IconCaption>brand-bisabolol</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-blackcurrant`} width={50} />
          <IconCaption>brand-blackcurrant</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-blueberry`} width={50} />
          <IconCaption>brand-blueberry</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-camellia`} width={50} />
          <IconCaption>brand-camellia</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-chamomile`} width={50} />
          <IconCaption>brand-chamomile</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-cherry`} width={50} />
          <IconCaption>brand-cherry</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-chia`} width={50} />
          <IconCaption>brand-chia</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-cobiolift`} width={50} />
          <IconCaption>brand-cobiolift</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-coconut`} width={50} />
          <IconCaption>brand-coconut</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-colloidal-oatmeal`} width={50} />
          <IconCaption>brand-colloidal-oatmeal</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-cucumber`} width={50} />
          <IconCaption>brand-cucumber</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-earth-minerals`} width={50} />
          <IconCaption>brand-earth-minerals</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-fucogel`} width={50} />
          <IconCaption>brand-fucogel</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-grapefruit`} width={50} />
          <IconCaption>brand-grapefruit</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-green-tea`} width={50} />
          <IconCaption>brand-green-tea</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-guava`} width={50} />
          <IconCaption>brand-guava</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-hyaloronic-acid`} width={50} />
          <IconCaption>brand-hyaloronic-acid</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-kakadu-plum`} width={50} />
          <IconCaption>brand-kakadu-plum</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-kiwi-fruit`} width={50} />
          <IconCaption>brand-kiwi-fruit</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-l-ascorbic-acid`} width={50} />
          <IconCaption>brand-l-ascorbic-acid</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-lactic-acid`} width={50} />
          <IconCaption>brand-lactic-acid</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-leaf`} width={50} />
          <IconCaption>brand-leaf</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-lime`} width={50} />
          <IconCaption>brand-lime</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-mandelic-acid`} width={50} />
          <IconCaption>brand-mandelic-acid</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-mango-butter`} width={50} />
          <IconCaption>brand-mango-butter</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-matilook`} width={50} />
          <IconCaption>brand-matilook</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-micronized-algae`} width={50} />
          <IconCaption>brand-micronized-algae</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-papaya-enzymes`} width={50} />
          <IconCaption>brand-papaya-enzymes</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-paw`} width={50} />
          <IconCaption>brand-paw</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-pineapple-enzymes`} width={50} />
          <IconCaption>brand-pineapple-enzymes</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-pineapple`} width={50} />
          <IconCaption>brand-pineapple</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-pomegranate`} width={50} />
          <IconCaption>brand-pomegranate</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-prickly-pear`} width={50} />
          <IconCaption>brand-prickly-pear</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-probiobalance`} width={50} />
          <IconCaption>brand-probiobalance</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-pumpkin`} width={50} />
          <IconCaption>brand-pumpkin</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-raspberry`} width={50} />
          <IconCaption>brand-raspberry</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-resveratrol`} width={50} />
          <IconCaption>brand-resveratrol</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-rose-geranium`} width={50} />
          <IconCaption>brand-rose-geranium</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-rose`} width={50} />
          <IconCaption>brand-rose</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-sea-buckthorn`} width={50} />
          <IconCaption>brand-sea-buckthorn</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-shea-butter`} width={50} />
          <IconCaption>brand-shea-butter</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-sunflower`} width={50} />
          <IconCaption>brand-sunflower</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-sweet-fennel`} width={50} />
          <IconCaption>brand-sweet-fennel</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-tomato`} width={50} />
          <IconCaption>brand-tomato</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-totarol`} width={50} />
          <IconCaption>brand-totarol</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-turmeric`} width={50} />
          <IconCaption>brand-turmeric</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-vanilla`} width={50} />
          <IconCaption>brand-vanilla</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-vit-a-like`} width={50} />
          <IconCaption>brand-vit-a-like</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-watermelon`} width={50} />
          <IconCaption>brand-watermelon</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-wattle`} width={50} />
          <IconCaption>brand-wattle</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`brand-zinc`} width={50} />
          <IconCaption>brand-zinc</IconCaption>
        </IconSwatch>
      </StyledColumn>
    </StyledRow>

    <StyledRow gutter justify={`start`} withSpacing withWrap>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`face-balanced`} width={50} />
          <IconCaption>face-balanced</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`face-balancing`} width={50} />
          <IconCaption>face-balancing</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`face-booster`} width={50} />
          <IconCaption>face-booster</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`face-calmer`} width={50} />
          <IconCaption>face-calmer</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`face-cleanser`} width={50} />
          <IconCaption>face-cleanser</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`face-clearer`} width={50} />
          <IconCaption>face-clearer</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`face-day`} width={50} />
          <IconCaption>face-day</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`face-day-circle`} width={50} />
          <IconCaption>face-day-circle</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`face-dry`} width={50} />
          <IconCaption>face-dry</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`face-exfoliants`} width={50} />
          <IconCaption>face-exfoliants</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`face-eye`} width={50} />
          <IconCaption>face-eye</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`face-firmer`} width={50} />
          <IconCaption>face-firmer</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`face-glowing`} width={50} />
          <IconCaption>face-glowing</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`face-heart`} width={50} />
          <IconCaption>face-heart</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`face-irritated`} width={50} />
          <IconCaption>face-irritated</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`face-makeup`} width={50} />
          <IconCaption>face-makeup</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`face-masks`} width={50} />
          <IconCaption>face-masks</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`face-moisturisers`} width={50} />
          <IconCaption>face-moisturisers</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`face-night`} width={50} />
          <IconCaption>face-night</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`face-night-circle`} width={50} />
          <IconCaption>face-night-circle</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`face-oils`} width={50} />
          <IconCaption>face-oils</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`face-oily`} width={50} />
          <IconCaption>face-oily</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`face-poppy`} width={50} />
          <IconCaption>face-poppy</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`face-pregnant`} width={50} />
          <IconCaption>face-pregnant</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`face-sensitive`} width={50} />
          <IconCaption>face-sensitive</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`face-sun`} width={50} />
          <IconCaption>face-sun</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`face-toners`} width={50} />
          <IconCaption>face-toners</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`face-unbalanced`} width={50} />
          <IconCaption>face-unbalanced</IconCaption>
        </IconSwatch>
      </StyledColumn>
    </StyledRow>

    <StyledRow gutter justify={`start`} withSpacing withWrap>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`payments-afterpay`} width={50} />
          <IconCaption>payments-afterpay</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`payments-applepay`} width={50} />
          <IconCaption>payments-applepay</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`payments-mastercard`} width={50} />
          <IconCaption>payments-mastercard</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`payments-paypal`} width={50} />
          <IconCaption>payments-paypal</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`payments-visa`} width={50} />
          <IconCaption>payments-visa</IconCaption>
        </IconSwatch>
      </StyledColumn>
    </StyledRow>

    <StyledRow gutter justify={`start`} withSpacing withWrap>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`social-facebook`} width={50} />
          <IconCaption>social-facebook</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`social-instagram`} width={50} />
          <IconCaption>social-instagram</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`social-pinterest`} width={50} />
          <IconCaption>social-pinterest</IconCaption>
        </IconSwatch>
      </StyledColumn>
      <StyledColumn width={`md:1/8`} gutter>
        <IconSwatch>
          <Icon name={`social-youtube`} width={50} />
          <IconCaption>social-youtube</IconCaption>
        </IconSwatch>
      </StyledColumn>
    </StyledRow>

    <StyledRow gutter justify={`start`} withSpacing withWrap>
      <StyledColumn width={`md:1/4`} gutter>
        <IconSwatch>
          <Icon name={`logo`} width={190} />
          <IconCaption>logo</IconCaption>
        </IconSwatch>
      </StyledColumn>
    </StyledRow>

    <H4 withSpacing={`xs`}>Accordion</H4>
    <Accordion
      items={[
        { title: `Item one`, children: <P>{`Content goes here`}</P> },
        { title: `Item two`, children: <P>{`Content goes here`}</P> },
        { title: `Item three`, children: <P>{`Content goes here`}</P> },
      ]}
      withSpacing
    />

    <H4 withSpacing={`xs`}>Forms</H4>
    <StyledRule withSpacing />
    <StyledRow gutter justify={`start`} withSpacing withWrap>
      <StyledColumn width={`md:1/3`} gutter>
        <StyledButton colour={`orange`} size={`primary`}>
          Primary Button
        </StyledButton>
      </StyledColumn>
      <StyledColumn width={`md:1/3`} gutter>
        <StyledButton colour={`purple`} size={`primary`}>
          Primary Button
        </StyledButton>
      </StyledColumn>
      <StyledColumn width={`md:1/3`} gutter>
        <StyledButton colour={`orange-outline`} size={`primary`}>
          Primary Button
        </StyledButton>
      </StyledColumn>
    </StyledRow>
    <StyledRow gutter justify={`start`} withSpacing withWrap>
      <StyledColumn width={`md:1/4`} gutter>
        <StyledButton colour={`orange`} size={`secondary`}>
          Secondary Button
        </StyledButton>
      </StyledColumn>
      <StyledColumn width={`md:1/4`} gutter>
        <StyledButton colour={`purple`} size={`secondary`}>
          Secondary Button
        </StyledButton>
      </StyledColumn>
      <StyledColumn width={`md:1/4`} gutter>
        <StyledButton colour={`orange-outline`} size={`secondary`}>
          Secondary Button
        </StyledButton>
      </StyledColumn>
      <StyledColumn width={`md:1/4`} gutter>
        <StyledButton colour={`purple-dark-outline`} size={`secondary`}>
          Secondary Button
        </StyledButton>
      </StyledColumn>
    </StyledRow>
    <StyledRow gutter justify={`start`} withSpacing withWrap>
      <StyledColumn width={`md:1/4`} gutter>
        <StyledLink colour={`orange-dark`} underline>
          <span />
          <span>Text Button</span>
          <Icon name={`right`} />
        </StyledLink>
      </StyledColumn>
    </StyledRow>

    <StyledRow gutter justify={`start`} withSpacing withWrap>
      <StyledColumn width={`md:1/2`} gutter>
        <StyledForm>
          <FormInput type={`email`} placeholder={`Email`} required full withSpacing />
          <FormInput type={`email`} placeholder={`Email`} required full withSpacing defaultValue={`hello@test.`} />
          <FormInput type={`email`} placeholder={`Email`} required full withSpacing defaultValue={`hello@test.com`} />
          <FormInput
            type={`email`}
            placeholder={`Email`}
            required
            full
            withSpacing
            defaultValue={`hello@test.`}
            errors={[{ code: 400, message: `Mmm... Invalid email address` }]}
          />
        </StyledForm>
      </StyledColumn>
      <StyledColumn width={`md:1/2`} gutter>
        <StyledForm>
          <FormInput type={`email`} disabled placeholder={`Email`} required full withSpacing />
          <FormInput type={`password`} disabled placeholder={`Password`} required full withSpacing />
          <FormSelect
            placeholder={`Country`}
            required
            full
            withSpacing
            options={[{ value: "Option 1" }, { value: "Option 2" }, { value: "Option 3" }]}
          />
          <FormSelect
            placeholder={`Country`}
            value={`Option 1`}
            required
            full
            withSpacing
            options={[{ value: "Option 1" }, { value: "Option 2" }, { value: "Option 3" }]}
          />
        </StyledForm>
      </StyledColumn>
      <StyledColumn gutter>
        <StyledForm>
          <FormTextarea placeholder={`Message`} required />
        </StyledForm>
      </StyledColumn>
    </StyledRow>

    <H4 withSpacing={`xs`}>Pills</H4>
    <StyledRule withSpacing />
    <Section colour={`orange-pastel`} withSpacing>
      <StyledRow gutter items={`center`} justify={`start`} withSpacing withWrap>
        <StyledColumn gutter>
          <StyledPills align={`center`}>
            <StyledPill active>150ml</StyledPill>
            <StyledPill>300ml</StyledPill>
            <StyledPill>600ml</StyledPill>
          </StyledPills>
        </StyledColumn>
      </StyledRow>
    </Section>

    <H4 withSpacing={`xs`}>Meatballs</H4>
    <StyledRule withSpacing />
    <StyledRow gutter items={`center`} justify={`start`} withSpacing withWrap>
      <StyledColumn gutter>
        <StyledMeatballs>
          <StyledMeatball active />
          <StyledMeatball />
          <StyledMeatball />
        </StyledMeatballs>
      </StyledColumn>
    </StyledRow>

    <H4 withSpacing={`xs`}>Breadcrumbs</H4>
    <StyledRule withSpacing />
    <StyledRow gutter items={`center`} justify={`start`} withSpacing withWrap>
      <StyledColumn gutter>
        <StyledBreadcrumbs>
          <StyledBreadcrumb>Home</StyledBreadcrumb>
          <StyledBreadcrumb>Account</StyledBreadcrumb>
          <StyledBreadcrumb>Create an account</StyledBreadcrumb>
        </StyledBreadcrumbs>
      </StyledColumn>
    </StyledRow>
  </StyledContainer>
)
